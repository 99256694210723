<template>
  <v-card>
    <v-toolbar color="orange darken-3" flat>
      <v-toolbar-title>
        <v-icon class="mr-2">mdi-cog-outline</v-icon> Manage File Type
      </v-toolbar-title>

      <v-spacer />

      <v-btn icon title="Click to close" @click="$emit('close')">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-toolbar>

    <v-alert v-if="error" type="error" dismissible>
      {{ errorMessage }}
    </v-alert>

    <!-- Search field -->
    <v-card-text>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-text>

    <!-- List of file types -->
    <v-card-text>
      <p>List of all added file types:</p>

      <v-data-table
        :headers="[
          { text: 'Name', value: 'name' },
          { text: 'Description', value: 'description' },
          { text: 'Signature Requirement', value: 'signature_requirement' },
          { text: 'Provided By', value: 'provided_by' },
          { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
        ]"
        :items="fileTypes"
        :items-per-page="-1"
        :loading="loadingProgress"
        :loading-text="loadingProgress ? 'Loading file types...' : ''"
        :search="search"
        class="bordered"
        hide-default-footer
        dense
      >
        <template v-slot:item.actions="{ item, index }">
          <div style="min-width: 80px;">
            <v-btn
              icon
              class="mr-2"
              color="teal"
              title="Click to edit"
              @click="editFileType(item)"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>

            <v-btn
              icon
              color="red"
              title="Click to delete"
              @click="deleteFileType(item)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider class="mt-4"></v-divider>

    <!-- Form to create a new category -->
    <v-card-text>
      <v-form ref="form" @submit.prevent="saveFileType">
        <v-text-field
          v-model="newFileType.name"
          :rules="[rule.required]"
          label="Name"
          placeholder="Enter file type name"
        />

        <v-textarea
          v-model="newFileType.description"
          :rules="[rule.required]"
          label="Description"
          rows="1"
          auto-grow
        />

        <div class="d-flex flex-wrap justify-space-between">
          <v-checkbox
            v-model="newFileType.view_company"
            label="View Company"
            hide-details
          />

          <v-checkbox
            v-model="newFileType.view_customer"
            label="View Customer"
            hide-details
          />

          <v-checkbox
            v-model="newFileType.customers"
            label="Customers"
            hide-details
          />

          <v-checkbox
            v-model="newFileType.properties"
            label="Properties"
            hide-details
          />

          <v-checkbox
            v-model="newFileType.signature_requirement"
            label="Signature Requirement"
            hide-details
          />
        </div>

        <v-select
          v-model="newFileType.provided_by"
          :items="providedByOptions"
          :rules="[rule.required]"
          label="Provided By"
          clearable
        />

        <div class="d-flex align-center justify-end">
          <v-btn text color="teal" type="submit" :disabled="actionProgress">
            <v-progress-circular v-if="actionProgress" indeterminate />
            <span v-else>
              {{ editingFileType ? "Save" : "+ Add File Type" }}
            </span>
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import API from "@/plugins/API";
import rule from "@/plugins/rule";

export default {
  data: () => ({
    error: null,
    errorMessage: "",
    loadingProgress: false,

    actionProgress: false,

    fileTypes: [],
    rule: rule,
    search: "",

    editingFileType: false,

    newFileType: {
      name: "",
      description: "",
      view_company: false,
      view_customer: false,
      customers: false,
      properties: false,
      signature_requirement: false,
      provided_by: null,
    },

    providedByOptions: ["Internal", "Lender Provided", "Customer Provided"],
  }),

  methods: {
    editFileType(item) {
      this.editingFileType = true;
      this.newFileType = item;
    },

    async deleteFileType(item) {
      if (window.confirm("Are you sure you want to delete this file type?")) {
        const index = this.fileTypes.indexOf(item);

        this.fileTypes.splice(index, 1);

        try {
          // API call to delete file type
          const response = await API().delete(
            `api/internal-admin/file-type/delete/${item.id}`
          );

          console.log("Status: ", response.status);
          console.log(response.data.message);
        } catch (error) {
          console.log(error);
          this.error = true;
          this.errorMessage = error.response.data.message;
        }
      } else {
        return;
      }
    },

    async saveFileType() {
      try {
        if (this.$refs.form.validate()) {
          this.actionProgress = true;

          if (this.editingFileType) {
            // API call to update category
            const response = await API().patch(
              "api/internal-admin/file-type/edit",
              {
                newFileType: this.newFileType,
              }
            );

            console.log("Status: ", response.status);
            console.log(response.data.message);

            const fileType = this.fileTypes.find(
              (e) => e.id === response.data.updated_file_type.id
            );

            // Handle update action
            fileType.name = response.data.updated_file_type.name;
            fileType.description = response.data.updated_file_type.description;
            fileType.view_company =
              response.data.updated_file_type.view_company;
            fileType.view_customer =
              response.data.updated_file_type.view_customer;
            fileType.customers = response.data.updated_file_type.customers;
            fileType.properties = response.data.updated_file_type.properties;
            fileType.signature_requirement =
              response.data.updated_file_type.signature_requirement;
            fileType.provided_by = response.data.updated_file_type.provided_by;

            this.editingFileType = false;

            this.newFileType = {
              name: "",
              description: "",
              view_company: false,
              view_customer: false,
              customers: false,
              properties: false,
              signature_requirement: false,
              provided_by: null,
            };

            this.$refs.form.resetValidation();
            this.actionProgress = false;
            return;
          }

          // Create new category if not editing
          // API call to create a new category
          const response = await API().post(
            "api/internal-admin/file-type/create",
            { newFileType: this.newFileType }
          );

          console.log("Status: ", response.status);
          console.log(response.data.message);

          // Handle create action
          const newFileType = response.data.new_file_type;

          this.fileTypes.push(newFileType);

          this.newFileType = {
            name: "",
            description: "",
            view_company: false,
            view_customer: false,
            customers: false,
            properties: false,
            signature_requirement: false,
            provided_by: null,
          };

          this.$refs.form.resetValidation();
          this.actionProgress = false;
        } else {
          return;
        }
      } catch (error) {
        console.log(error);
        this.actionProgress = false;
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    async getFileTypes() {
      try {
        this.loadingProgress = true;
        // API call to get all fileTypes
        const response = await API().get(
          "api/internal-admin/file-type/get-all-file-types"
        );

        console.log("Status: ", response.status);
        console.log(response.data.message);

        this.fileTypes = response.data.file_types;

        this.loadingProgress = false;
      } catch (error) {
        console.log(error);
        this.loadingProgress = false;
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },
  },

  mounted() {
    this.getFileTypes();
  },
};
</script>
